import React, { useRef, useState, useEffect, useCallback } from "react";
import BottomSheet from "../../components/BottomSheet";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleLogin from "react-google-login";
import useGoogleLogin from "../../hooks/useGoogleLogin";
import { env } from "../../env";
import { getLocalStorage } from "../../utils/function/localStorage";
import authSplashScreen1 from "../../assets/images/SplashScreen1.png";
import authSplashScreen2 from "../../assets/images/SplashScreen2.png";
import authSplashScreen3 from "../../assets/images/SplashScreen3.png";

const SplashScreen = () => {
  const navigate = useNavigate();
  const token = getLocalStorage("tp_Aa_uth_access");
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const sponsorID =
    searchParams.get("sponsorId") || searchParams.get("sponsorid");

  useEffect(() => {
    console.log(location.search);
  }, [location.search]); // Runs only when the URL query changes

  // Slide content
  const slides = [
    {
      image: authSplashScreen1,
      title: "A Platform For Online Earners",
      subtitle:
        "Task Planet Is An Online Money Earning Website For The Individuals Looking For Small Tasks And Getting Paid For It",
    },
    {
      image: authSplashScreen2,
      title: "Best And Trusted Platform",
      subtitle:
        "Task Planet Is An Online Money Earning Website For The Individuals Looking For Small Tasks And Getting Paid For It",
    },
    {
      image: authSplashScreen3,
      title: "Earn Commisions From Team",
      subtitle: "Earn Every Time Your Team Completes any Task",
    },
  ];

  // Handle manual scroll detection
  const onScroll = useCallback(
    (e) => {
      if (!carouselRef.current) return;

      const scrollLeft = e.target.scrollLeft;
      const slideWidth = carouselRef.current.clientWidth;
      const newIndex = Math.round(scrollLeft / slideWidth);

      if (newIndex !== currentSlide) {
        setCurrentSlide(newIndex);
      }
    },
    [currentSlide]
  );

  // Auto slide functionality
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentSlide + 1) % slides.length;
      setCurrentSlide(nextIndex);

      if (carouselRef.current) {
        const scrollAmount = carouselRef.current.clientWidth * nextIndex;
        carouselRef.current.scrollTo({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [currentSlide, slides.length]);

  // Google sign in
  const { responseGoogle, resFailed } = useGoogleLogin();

  return (
    <div className="tpu__splashScreen__wrapper layoutContainer">
      {/* Slider with scroll-based swiping */}
      <div className="tpu__splashScreen__slider">
        <div
          className="tpu__splashScreen__carousel"
          ref={carouselRef}
          onScroll={onScroll}
        >
          {slides.map((slide, index) => (
            <div className="tpu__splashScreen__slide" key={index}>
              <div className="tpu__splashScreen__content">
                <div className="tpu__splashScreen__img">
                  <img
                    className="tpu__splashScreen__img__img"
                    src={slide.image}
                    alt={`splash screen ${index + 1}`}
                  />
                </div>
                <div className="tpu__splashScreen__title">
                  <h2>{slide.title}</h2>
                </div>
                <div className="tpu__splashScreen__para">
                  <p>{slide.subtitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Dot indicators */}
        <div className="tpu__splashScreen__dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentSlide === index ? "active" : ""}`}
              onClick={() => {
                setCurrentSlide(index);
                carouselRef.current?.scrollTo({
                  left: carouselRef.current.clientWidth * index,
                  behavior: "smooth",
                });
              }}
            />
          ))}
        </div>
      </div>

      {/* Bottom Sheet */}
      <BottomSheet height="40%">
        <div className="tpu__splashScreen__bottomSheet__content">
          <div className="section__title">
            <h2>Login with Task Planet</h2>
          </div>
          <div className="section__para">
            <p>
              Login to earn points using various amazing and easy tools provided
              in the app and then use earned points for reward!!
            </p>
          </div>
          <div className="google__login__btn">
            {token ? (
              <button
                type="button"
                className="other__login"
                onClick={() => navigate("/home")}
              >
                Go To Dashboard
              </button>
            ) : (
              <>
                <GoogleLogin
                  clientId={env.google_client_id}
                  buttonText="Sign in with google"
                  onSuccess={responseGoogle}
                  onFailure={resFailed}
                  cookiePolicy={"single_host_origin"}
                  className="google__btn"
                />
                <button
                  type="button"
                  className="other__login"
                  onClick={() => {
                    const path = sponsorID
                      ? `/authSplashScreen?sponsorId=${sponsorID}`
                      : "/authSplashScreen";
                    navigate(path);
                  }}
                >
                  Other Login Method
                </button>
              </>
            )}
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default SplashScreen;
